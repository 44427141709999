.image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 35vh;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  &.jamie {
    background-position: top center !important;

    @media (min-width: $desktop) {
      //max-width: 60%;
      margin: 0 auto;

      .p--small {
        //max-width: 60%;
        margin: 0 auto !important;
      }
    }
  }

  @media (min-width: $desktop) {
    height: 45vh;
  }
}

@media (min-width: $desktop) {

  .h1--lined--dark.p--small {
    //max-width: 60%;
    margin: 0 auto !important;
  }
}

.rezept-icon {
  height: 15px;
  width: 15px;
  margin-bottom: -3px;

  &.togo {
    width: auto;
  }

  @media (min-width: $ipad) {
    height: 22px;
    width: 22px;
    margin-bottom: -4px;
  }

  @media (min-width: $desktop) {
    height: 22px;
    width: 22px;
    margin-bottom: -4px;
  }
}

.rezepte-meta-elem {
  text-align: left;
  margin-right: 5px;

  &:last-of-type {
    white-space:nowrap;
  }

  @media (min-width: $ipad) {
    margin-right: 10px;
  }

  @media (min-width: $desktop) {
    margin-right: 10px;
  }
}

.meta--clock {
  font-style: italic;
}

.rezepte-meta-elem {
  display: inline;
}

.rezepte-colored-stripe {
  height: 13px;
}

