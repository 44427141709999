.progress-bar {

  .logo-text-small {
    margin-top: -100px;
    letter-spacing:0;
    line-height: 1.1;
    padding-bottom: 8px;

    @media (max-width: $phonesmall) {
      margin-top: -80px;
    }

    @media (min-width: $ipad) {
      margin-top: -180px;
    }

    @media (min-width: $ipad_pro) {
      margin-top: -220px;
    }

    @media (min-width: $desktop) {
      margin-top: -80px;
    }
  }
}