* {
  box-sizing: border-box;
}

html {
    /*the next line is important to prevent the body to jump*/
    overflow-y: scroll;
}

@font-face {
    font-family: 'FaktPro-Blond';
    font-style: normal;
    font-weight: normal;
    src:
            local('FaktPro-Blond'),
            local('FaktPro-Blond'),
            url(
            ./assets/fonts/FaktPro-Blond.otf
            )format('opentype');
}

@font-face {
    font-family: 'FaktPro-Light';
    font-style: normal;
    font-weight: normal;
    src:
            local('FaktPro-Light'),
            local('FaktPro-Light'),
            url(
            ./assets/fonts/FaktPro-Light.otf
            )format('opentype');
}

@font-face {
    font-family: 'FaktPro-Medium';
    font-style: normal;
    font-weight: normal;
    src:
            local('FaktPro-Medium'),
            local('FaktPro-Medium'),
            url(
            ./assets/fonts/FaktPro-Medium.otf
            )format('opentype');
}

@font-face {
    font-family: 'FaktPro-Normal';
    font-style: normal;
    font-weight: normal;
    src:
            local('FaktPro-Normal'),
            local('FaktPro-Normal'),
            url(
            ./assets/fonts/FaktPro-Normal.otf
            )format('opentype');
}

@font-face {
    font-family: 'Fontspring-DEMO-panton-bold';
    font-style: normal;
    font-weight: normal;
    src:
            local('Fontspring-DEMO-panton-bold'),
            local('Fontspring-DEMO-panton-bold'),
            url(
            ./assets/fonts/Fontspring-DEMO-panton-bold.otf
            )format('opentype');
}

@font-face {
    font-family: 'Fontspring-DEMO-panton-extrabold';
    font-style: normal;
    font-weight: normal;
    src:
            local('Fontspring-DEMO-panton-extrabold'),
            local('Fontspring-DEMO-panton-extrabold'),
            url(
            ./assets/fonts/Fontspring-DEMO-panton-extrabold.otf
            )format('opentype');
}

@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: normal;
    src:
            local('Gilroy-Light'),
            local('Gilroy-Light'),
            url(
            ./assets/fonts/Gilroy-Light.otf
            )format('opentype');
}

@font-face {
    font-family: 'MinionPro-Regular';
    font-style: normal;
    font-weight: normal;
    src:
            local('MinionPro-Regular'),
            local('MinionPro-Regular'),
            url(
            ./assets/fonts/MinionPro-Regular.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Book';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Book'),
            local('Stag_Sans-Book'),
            url(
            ./assets/fonts/Stag_Sans-Book.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Light';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Light'),
            local('Stag_Sans-Light'),
            url(
            ./assets/fonts/Stag_Sans-Light.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Light-Italic';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Light-Italic'),
            local('Stag_Sans-Light-Italic'),
            url(
            ./assets/fonts/Stag_Sans-Light-Italic.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Medium';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Medium'),
            local('Stag_Sans-Medium'),
            url(
            ./assets/fonts/Stag_Sans-Medium.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Semibold';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Semibold'),
            local('Stag_Sans-Semibold'),
            url(
            ./assets/fonts/Stag_Sans-Semibold.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans-Thin';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans-Thin'),
            local('Stag_Sans-Thin'),
            url(
            ./assets/fonts/Stag_Sans-Thin.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans_Round-Book';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans_Round-Book'),
            local('Stag_Sans_Round-Book'),
            url(
            ./assets/fonts/Stag_Sans_Round-Book.otf
            )format('opentype');
}

@font-face {
    font-family: 'Stag_Sans_Round-Medium';
    font-style: normal;
    font-weight: normal;
    src:
            local('Stag_Sans_Round-Medium'),
            local('Stag_Sans_Round-Medium'),
            url(
            ./assets/fonts/Stag_Sans_Round-Medium.otf
            )format('opentype');
}

@font-face {
    font-family: 'TipoType-TrasandinaLight';
    font-style: normal;
    font-weight: normal;
    src:
            local('TipoType-TrasandinaLight'),
            local('TipoType-TrasandinaLight'),
            url(
            ./assets/fonts/TipoType-TrasandinaLight.otf
            )format('opentype');
}

body {
    margin: 0;
    font-family: "Stag_Sans-Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*the next line is important to prevent the drawer from jumping*/
    padding-right: 0 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.fade {
    animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}

.fade1 {
    animation: fade1-in-keyframes 1s;
}
@keyframes fade1-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}

.fade2 {
    animation: fade2-in-keyframes 1s;
}
@keyframes fade2-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}

.fade3 {
    animation: fade3-in-keyframes 1s;
}
@keyframes fade3-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}


.logo-spin {
    animation: logo-spin-animation infinite 10s linear;
    //height: 40vmin;
    pointer-events: none;
  overflow: hidden;
  margin: 0 auto;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

@keyframes logo-spin-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: none !important;
}


.picker-highlight {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.picker-container .picker-inner,
.picker-container .picker-column .picker-item {
    width: 100%;
}

.picker-container {
    padding-left: 20px;
    padding-right: 20px;
}

#number-block {
/*    padding-top: 10px;
    padding-bottom: 10px;*/
}

/*#number-block.number-block--temperature .picker-scroller{
    display: flex;
    flex-direction: column-reverse;
}*/

@media (max-width: 960px) {
    .selectWrapperHeadline {

        padding-bottom: 0 !important;
        margin-bottom: -15px !important;
    }

/*    .picker-container {
        height: 400px !important;
    }*/

    #number-block .picker-item {
        padding-top: 1px;
        font-size: 15px;
        font-weight: bold !important;
    }
    #number-block .picker-scroller {
    }
}


.picker-item:active {background: transparent}
.picker-container .picker-column .picker-item.picker-item-selected {
    color: #ffffff !important;
    background: transparent;
    font-weight: bold;
    /*line-height: 35px !important;*/
}

.picker-inner {
    width: 300px;
    margin: 0 auto;
}

.picker-item {
    width: 300px;
    padding-top: 1px;
}

.picker-container .picker-inner {padding: 0 !important}
.picker-item:hover {cursor: pointer}


/*#white-block {*/
/*    height: 100vh;*/
/*}*/

#white-block .picker-highlight {
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 12px;
    width: 100%;
}

#white-block .picker-highlight:before,
#white-block .picker-highlight:after {
    display: none;
}

#white-block .picker-column {
    border-radius: 50%;
    transform: scale(1,0.9);
}

#white-block .picker-inner {
    margin: 0 auto !important;
    max-width: 300px;
}

#number-block .picker-container .picker-inner {
    -webkit-mask-box-image: linear-gradient(0deg, transparent, transparent 5%, #fff 50%, #fff 50%, transparent 100%, transparent);
    mask-image: linear-gradient(0deg, transparent, transparent 5%, #fff 50%, #fff 50%, transparent 100%, transparent);
}

#white-block .picker-container .picker-inner {
    -webkit-mask-box-image: linear-gradient(0deg, transparent, transparent 5%, #fff 50%, #fff 50%, transparent 95%, transparent);
    mask-image: linear-gradient(0deg, transparent, transparent 5%, #fff 50%, #fff 50%, transparent 95%, transparent);
}

.selectWrapperHeadlineTop,
.selectWrapperHeadlineBottom,
.selectWrapperHeadline,
#selectWrapperInner .MuiFormControlLabel-label {
    letter-spacing: 1.3px !important;
    font-weight: bold;
}

/*@media (max-width: 960px) {
    .selectWrapperHeadlineTop {
        margin-bottom: -35px !important;
        font-size: 13px !important;
    }

    .selectWrapperHeadlineBottom {
        margin-top: -35px !important;
        font-size: 13px !important;
    }
    .selectWrapperHeadline {
        font-size: 13px !important;
    }

    #selectWrapperInner .MuiFormControlLabel-label {
        font-size: 13px !important;
        font-family: "Stag_Sans-Light";

    }
}*/


/*
html, #root {
    overflow-y: hidden;
}*/

/*
.picker-container .picker-column .picker-item {
  margin-left: 0px !important;
}*/
