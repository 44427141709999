.button--primary {
  width: 100%;
  min-height: 60px;
  border-radius: 0;
  background: $primary;
  text-transform: none !important;
  line-height: 1;
  font-weight: bold !important;
  font-size: $h1_font-size;

  &.MuiButton-root {
    font-size: $h1_font-size;

    @media (min-width: $ipad) {
      font-size: $h1_font-size * $ipad_resp-factor !important;
    }

    @media (min-width: $ipad_pro) {
      font-size: $h1_font-size * $ipadpro_resp-factor !important;
    }

    @media (min-width: $desktop) {
      font-size: $h1_font-size * $desktop_resp-factor !important;
    }
  }

  span {
    letter-spacing: 3px !important;
  }
}

.scrollButtonWrapper {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }

  button:hover {cursor: pointer !important}
}

/*
#verzicht .MuiRadio-root {
  padding: 0px !important;
}

#verzicht .MuiIconButton-label {
  transform: scale(1) !important;
}

#verzicht .MuiFormControlLabel-root {
  padding-top: 14px !important;
}

*/

.navi-icon-profile {
  max-height: 30px;

  @media (min-width: $ipad) {
    max-height: 50px;
  }

  @media (min-width: $ipad_pro) {
    max-height: 70px;
  }

  @media (min-width: $desktop) {
    max-height: 40px;
  }
}

.MuiTab-textColorInherit.Mui-selected {
  background: #34292D;
  color: white;
}
//
//.MuiTabs-scrollButtons {
//  display: none !important;
//}
.makeStyles-root-77 {
  flex-direction: column;
}