.eom-a {

  .rezepte-colored-stripe {
    background: #66f7fc;
  }

  .circle-back {
    fill: #66f7fc;
  }
}

.eom-b {

  .rezepte-colored-stripe {
    background: #66ff66;
  }

  .circle-back {
    fill: #66ff66;
  }
}

.eom-c {

  .rezepte-colored-stripe {
    background: #fcee4d;
  }

  .circle-back {
    fill: #fcee4d;
  }
}

.eom-d {

  .rezepte-colored-stripe {
    background: #ffa94c;
  }

  .circle-back {
    fill: #ffa94c;
  }
}

.eom-e {

  .rezepte-colored-stripe {
    background: #ff68b5;
  }

  .circle-back {
    fill: #ff68b5;
  }
}

.eom-f {

  .rezepte-colored-stripe {
    background: #d977ee;
  }

  .circle-back {
    fill: #d977ee;
  }
}

.eom-g {

  .rezepte-colored-stripe {
    background: #6779ff;
  }

  .circle-back {
    fill: #6779ff;
  }
}