/*
BREAKPOINTS (mobile first -> min width)
*/

$phonesmall: 320px; //down

$ipad: 767px;
$ipad-pro: 1024px;
$desktop: 1280px;
$desktop_large: 1800px;

/*
RESPONSIVE FACTORS
*/
/*$ipad_resp-factor: 1.4;*/
$phonesmall_resp-factor: 0.8;
$ipad_resp-factor: 2;
//$ipadpro_resp-factor: 1.7;
$ipadpro_resp-factor: 2.7;
$desktop_resp-factor: 1.4;

/*
COLORS
*/
$primary: #34292D;
$light: #ffffff;
$grey_bg: #cfd8dc;

$eom_blue: #6779ff;

/*
FONT SIZES
*/
$logoicon_font-size: 65px;
$logotext_font-size: 38.5px;
$logotextsmall_font-size: 15px;

$p_font-size: 13.5px;
$p_small_font-size: 12px;
$h1_font-size: 20.5px;
$h1_large_font-size: 30px;

