.MuiDrawer-root.MuiDrawer-modal {
  z-index: 1900 !important;
}

.MuiDrawer-paper {
  width: 100%;

  @media (min-width: $ipad_pro) {
    width: 100%
  }

  @media (min-width: $desktop) {
    width: 50%
  }

  @media (min-width: $desktop_large) {
    width: 50%
  }
}

.drawer-link {
  border-bottom: 1px solid $light;
  margin-bottom: 0;
  margin-top: 15px;
  padding-bottom: 2px;
  padding-right: 20px;
  @media (min-width: $ipad_pro) {
    margin-top: 22px;
  }

  &:after {
    content: url("./assets/images/next.svg");
    float: right;
    transform: scale(0.35);
    position: absolute;
    right: -6px;
    bottom: -5px;

    @media (min-width: $ipad) {
      transform: scale(0.7);
      bottom: -2px;
      right: -3px;
    }

    @media (min-width: $ipad_pro) {
      transform: scale(0.85);
      bottom: 0;
      right: -2px;
    }

    @media (min-width: $desktop) {
      transform: scale(0.55);
      bottom: -3px;
      right: -5px;
    }
  }
}

.drawer-link--dark {
  @extend .drawer-link;
  border-bottom: 1px solid #3e3e3e !important;
  padding-right: 0;

  &:after {
    content: url("./assets/images/next_dark.jpg");
    bottom: unset;
    right: 4px;
    transform: scale(0.24);
    margin-top: -35px;

    @media (min-width: $ipad) {
      transform: scale(0.45);
      margin-top: -25px;
      margin-right: 35px;
    }

    @media (min-width: $ipad_pro) {
      transform: scale(0.55);
      margin-top: -20px;
      margin-right: 35px;
    }

    @media (min-width: $desktop) {
      transform: scale(0.3);
      margin-top: -30px;
      margin-right: 30px;
    }

    @media (min-width: 1280px){
      position: unset;
      right: auto;
      margin-right: -10px;
    }
  }
  &:before {
    @media (min-width: 1280px){
      position: unset;
      right: auto;
      margin-left: 40px;
      content: ''
    }
  }
}

.drawer-link--none {
  @extend .drawer-link;
  padding-right: 0;
  border-bottom: 1px solid #3e3e3e !important;
  margin-top: 6px;

  @media (min-width: $desktop) {
    margin-top: 12px;
  }

  &:after {
    display: none;
  }
}

.drawer-link--favourite {

  @media (min-width: $ipad) {
    padding-top: 10px;
    padding-bottom: 3px;
  }

  @media (min-width: $ipad_pro) {
    padding-top: 15px;
    padding-bottom: 3px;
  }

  @media (min-width: $desktop) {
    padding-top: 1px;
    padding-bottom: 3px;
  }

  &:before {
    content: url("./assets/images/favourites.jpg");
    position: absolute;
    left: -60px;
    transform: scale(0.125);
    margin-top: -63px;

    @media (min-width: $ipad) {
      left: -15px;
      transform: scale(0.28);
      margin-top: -55px;
    }

    @media (min-width: $ipad_pro) {
      left: -8px;
      transform: scale(0.35);
      margin-top: -55px;
    }

    @media (min-width: $desktop) {
      left: -25px;
      transform: scale(0.18);
      margin-top: -62px;
    }
  }
}

.favourite-not-toggled {

  .drawer-link--favourite {
    &:before {
      content: url("./assets/images/favourites.jpg");
    }
  }
}

.favourite-toggled {

  .drawer-link--favourite {
    &:before {
      content: url("./assets/images/favourites_red.jpg");
    }
  }
}

.h1--drawer {
    @media(min-width: $ipad) {
      margin-top: 20px !important;
    }

    @media(min-width: $ipad-pro) {
      margin-top: 45px !important;
    }

    @media(min-width: $desktop) {
      margin-top: 20px !important;
    }

}

.MuiDrawer-paper {
  padding-left: 6% !important;
  padding-right: 6% !important;

  @media(min-width: $desktop) {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
