#root{

  @media (min-width: $desktop) {
    //background: $grey_bg;
    background: #ffffff;
  }
}

.landingpage-top-wrapper {
  background: $primary;
  padding: 20px 30px 10px 30px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 76px;

  @media (min-width: $ipad) {
    margin-top: 90px;
  }

  @media (min-width: $ipad_pro) {
    margin-top: 147px;
  }

  @media (min-width: $desktop) {
    margin-top: 35px;
    margin-right: 0;
    max-width: 610px;
    //margin-top: 50px;
    //margin-right: 160px;
    //max-width: 610px;
  }
}

.main-landingpage-loading {
  text-align: center;
  margin: 0 auto;
  min-height: 100%;
  min-height: 100vh;
}

.main-landingpage-loading-article {
  margin: 0 auto;
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.main-landingpage-loaded {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 84px);
  background: $light;
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop) {
    background: url(assets/images/knauer_w_overlay.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 70px;
  }

  @media (max-width: $desktop) {
    background: url(assets/images/knauer_w_overlay_blurred.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 10px;
  }
}

.main {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background: $light;
}

.app-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  min-height: 100vh;
  margin: 0 auto;

  @media (min-width: $ipad) {
    width: 100%
  }

  @media (min-width: $ipad_pro) {
    width: 100%
  }

  //@media (min-width: $desktop) {
  //  width: 50%
  //}
  //
  //@media (min-width: $desktop_large) {
  //  width: 30%
  //}
}

article {
  margin-top: 76px;
}

.article {
  margin: 0 auto;

  @media (min-width: $desktop) {
    width: 860px !important;
  }

  @media (min-width: $desktop_large) {
    width: 860px !important;
  }
}

.article-inner {
  padding: 15px 20px 15px;

  @media (min-width: $ipad) {
    padding: 20px 50px 20px;
  }

  @media (min-width: $ipad_pro) {
    padding: 30px 50px 25px;
  }

  @media (min-width: $desktop) {
    //padding: 15px 0 20px;
    padding: 25px 0 25px;
  }

  img {
    max-width: 60%;
    margin: 0 auto;
  }
}

.app-wrapper--logged {
  @extend .app-wrapper;
  top: 0;
  background: url("./assets/images/background.jpg") no-repeat center top;
  background-size: cover;
}

.app {
  display: flex;
  min-height: 100%;
  min-height: 100vh;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #34292D;
  box-shadow: 10px 10px 4px 2px rgba(168,168,168,1);
  border-radius: 12px;
  height: 460px;

  @media (min-width: $ipad) {
    height: 800px;
  }

  @media (min-width: $ipad_pro) {
    height: 1100px;
  }

  @media (min-width: $desktop) {
    height: 800px;
  }


  label {
    color: #ffffff !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    @media (min-width: $ipad) {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }
}


.selectWrapperHeadline {
  text-align: center;
  color: #ffffff;
  line-height: 2;
  padding: 20px;
  font-weight: bold;
  margin-bottom: 10px

}

#eom-new-wrapper article {
  @media (max-width: $ipad) {
    min-height: calc(100vh - 100px) !important;
  }
}

.article--with-stripe {
  margin-top: 70px;

  @media (min-width: $ipad) {
    margin-top: 116px;
  }

  @media (min-width: $ipad_pro) {
    margin-top: 149px;
  }

  @media (min-width: $desktop) {
    margin-top: 76px;
  }
}

.fade-wrapper--secondtext {
  margin-top: 10px;

  @media (min-width: $ipad) {
    margin-top: 50px;
  }

  @media (min-width: $ipad_pro) {
    margin-top: 30px;
  }

  @media (min-width: $desktop) {
    margin-top: 30px;
  }
}

#logo-icon-large-text {

  h1 {
    line-height: 1.25 !important;
    margin-top: 0 !important;
  }
}
