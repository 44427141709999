.cart-top {

  p {
    text-align: center;
    margin-bottom: 2px;
    margin-top: 10px;

    @media (min-width: $desktop) {
      margin-bottom: 5px;
      margin-top: 20px;
    }
  }

  .cart-top-elem-withborder {
    margin-top: 0;
    border: 1px solid #3e3e3e;
    border-radius: 6px;
    padding: 4px 0 3px;

    @media (min-width: $desktop) {
      margin-top: 0;
      padding: 7px 0 5px;
    }
  }
}

.cart-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;

  &:first-of-type {
    margin-top: 30px;

    @media (min-width: $desktop) {
      margin-top: 50px;
    }
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 20px;

    @media (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  &.cart-table--calories {

    span:first-of-type {
      flex: 3.3;
      text-align: left;
    }

    span {
      flex: 1px;
      text-align: right;
    }
  }
}

.calories-additional-text {
  text-align: center;
  margin-top: 30px;

  @media (min-width: $desktop) {
    margin-top: 50px;
  }
}

.calories-button {
  color: white;
  background: $primary;
  border-radius: 6px;
  padding: 15px;

  span {
    font-weight: normal;
  }
}

.erinnerungen-headline {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:last-of-type {
    font-size: 118%;
    position: absolute;
    right: 18px;
    margin-bottom: -10px;
    //padding-top: 2px;

    @media (min-width: $ipad) {
      right: 47px;
    }

    @media (min-width: $ipad_pro) {
      right: 47px;
    }

    @media (min-width: $desktop) {
      right: 47px;
    }
  }
}