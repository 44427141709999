label {
  letter-spacing: 2.5px !important;
  color: #b9b5b6 !important;
  font-weight: bold !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.6) !important;
}

.MuiFilledInput-input {
  font-weight: bold !important;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
  display: none;
}
