.logo-icon {
  font-size: $logoicon_font-size * 0.9 !important;
  margin-top: 15px;

  @media (max-width: $phonesmall) {
    font-size: $logoicon_font-size * 0.8 !important;
  }

  @media (min-width: $ipad) {
    font-size: $logoicon_font-size * $ipad_resp-factor * 0.9 !important;
    margin-top: 35px;
  }

  @media (min-width: $ipad_pro) {
    font-size: $logoicon_font-size * $ipadpro_resp-factor * 1 !important;
    margin-top: 45px;
  }

  @media (min-width: $desktop) {
    font-size: $logoicon_font-size * $desktop_resp-factor * 1 !important;
  }
}

.logo-text {
  border-bottom: 2px solid $light;
  padding: 0 25px 5px 25px;
  margin: 0 auto;
  font-weight: 100;
  color: $light;
  line-height: 1;
  font-family: "Fontspring-DEMO-panton-bold";
  font-size: $logotext_font-size;

  @media (max-width: $phonesmall) {
    font-size: $logotext_font-size * $phonesmall_resp-factor * 1.1 !important;
    padding: 0 10px 5px 10px;
  }

  @media (min-width: $ipad) {
    font-size: $logotext_font-size * $ipad_resp-factor !important;
    padding: 0 35px 5px 35px;
  }

  @media (min-width: $ipad_pro) {
    font-size: $logotext_font-size * $ipadpro_resp-factor * 1.1 !important;
  }

  @media (min-width: $desktop) {
    font-size: $logotext_font-size * $desktop_resp-factor * 1.1 !important;
  }
}

.logo-text-small {
  color: $light;
  line-height: 2;
  font-family: "FaktPro-Medium";
  letter-spacing: 1.2px;
  font-weight: normal;
  margin: 0;
  font-size: $logotextsmall_font-size;

  @media (max-width: $phonesmall) {
    font-size: $logotextsmall_font-size * $phonesmall_resp-factor * 1.1 !important;
  }

  @media (min-width: $ipad) {
    font-size: $logotextsmall_font-size * $ipad_resp-factor  * 1.1!important;
  }

  @media (min-width: $ipad_pro) {
    font-size: $logotextsmall_font-size * $ipadpro_resp-factor * 1.18  !important;
  }

  @media (min-width: $desktop) {
    font-size: $logotextsmall_font-size * $desktop_resp-factor * 1.15 !important;
  }
}

.appbar {
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 5px;

  @media (max-width: $phonesmall) {
    padding-top: 5px;
    padding-bottom: 2px;
  }

  @media (min-width: $ipad) {
    padding-top: 16px;
    padding-bottom: 13px;
  }

  @media (min-width: $ipad_pro) {
    padding-top: 16px;
    padding-bottom: 13px;
  }

  @media (min-width: $desktop) {
    padding-top: 12px;
    padding-bottom: 8px;
  }
}

.logo-appbar {
  font-size: 35px !important;

  @media (max-width: $phonesmall) {
    font-size: 25px !important;
  }

  @media (min-width: $ipad) {
    font-size: 35px *1.6 !important;
  }

  @media (min-width: $ipad_pro) {
    font-size: 35px *2.3 !important;
  }

  @media (min-width: $desktop) {
    font-size: 35px *1 !important;
  }
}

.logo-text-appbar {
  font-size: 125px !important;
  height: auto !important;

  @media (max-width: $phonesmall) {
    font-size: 100px !important;
  }

  @media (min-width: $ipad) {
    font-size: 125px * 1.6 !important;
  }

  @media (min-width: $ipad_pro) {
    font-size: 125px * 2.3 !important;
  }

  @media (min-width: $desktop) {
    font-size: 125px *1.1 !important;
  }
}



.hamburger-button {
  margin-top: -20px !important;
  border-radius: 0 !important;
  .app-bar-icon {
    width: 40%;
    height: 40%;

    @media (max-width: $phonesmall) {
      width: 30%;
      height: 30%;
      margin-top: 0 !important;
    }

    @media (min-width: $ipad) {
      width: 65%;
      height: 65%;
    }

    @media (min-width: $ipad_pro) {
      width: 85%;
      height: 85%;
    }

    @media (min-width: $desktop) {
      width: 40%;
      height: 40%;
    }
  }
}

.placeholder-button {
  margin-top: -20px !important;
  padding-left: 0 !important;
}

.back-button {
  margin-top: -20px !important;
  padding-left: 10px !important;

  .app-bar-icon--back {
    width: 45%;
    height: 45%;

    @media (max-width: $phonesmall) {
      width: 30%;
      height: 30%;
      margin-top: 0 !important;
    }


    @media (min-width: $ipad) {
      width: 70%;
      height: 70%;
    }

    @media (min-width: $ipad_pro) {
      width: 85%;
      height: 85%;
    }
    @media (min-width: $desktop) {
      width: 50%;
      height: 50%;
    }
  }
}

