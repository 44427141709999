//circle with big letters in middle
.tcm-text-circle{
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  resize:both;
  margin-top: 25px;
  margin-bottom: 25px;
  overflow: auto;
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;

  @media (min-width: $ipad) {
    max-width: 70%;
    padding-bottom: 70%;
    left: 50%;
    transform: translateX(-50%)
  }

  svg {
    overflow: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: 98%;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .circle-back {
    //fill: #3DB8A4;

    &.white {
      fill: #ffffff;
    }
  }

  text {
    fill: #fff;
    font-size: 4.5rem;
    font-family: "Stag_Sans_Round-Book"
  }
}

//commented circle
.logoIconLargeContainer {
  position: relative;
  text-align: center;
  //max-width: 470px;
  margin: 0 auto;

  .iconLargeWrapper {
    //max-width: 470px;
    margin: 0 auto;
  }

  .logoIconLargeText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .arrowUp {
    position: absolute;
    margin-top: -29px;
    margin-left: -55px;
    width: 0;
    height: 0;
    border-bottom: 25px solid $primary;
    border-right: 35px solid transparent;

    @media(min-width: $ipad) {
      margin-top: -73px;
      margin-left: -127px;
      border-bottom: 51px solid $primary;
      border-right: 76px solid transparent;
    }

    @media(min-width: $ipad-pro) {
      margin-top: -87px;
      margin-left: -152px;
      border-bottom: 47px solid #34292D;
      border-right: 77px solid transparent;
    }

    @media(min-width: $desktop) {
      margin-top: -38px;
      margin-left: -79px;
      border-bottom: 51px solid $primary;
      border-right: 76px solid transparent;
    }
  }

  .tooltip {
    position: absolute;
    margin-top: 65px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 300px;
    padding: 15px;
    background: $primary;
    color: $light;
    border-radius: 12px;
    box-shadow: 15px 15px 17px -6px rgba(61,61,61,1);

    @media(min-width: $ipad) {
      min-width: 560px;
      max-width: 560px;
      margin-top: 58px;
    }

    @media(min-width: $ipad-pro) {
      min-width: 760px;
      max-width: 760px;
      margin-top: 127px;
    }

    @media(min-width: $desktop) {
      margin-top: 85px;
      min-width: 460px;
      max-width: 460px;
    }
  }
}

#no-g2 {

  .g2 {
    display: none;
  }

  .arrowUp {
    margin-top: 5px;
    margin-left: -20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 30px solid $primary;

    @media(min-width: $ipad) {
      margin-top: 13px;
      margin-left: -34px;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-bottom: 45px solid $primary;
    }

    @media(min-width: $ipad-pro) {
      margin-top: 23px;
      margin-left: -53px;
      border-left: 55px solid transparent;
      border-right: 55px solid transparent;
      border-bottom: 65px solid $primary;
    }

    @media(min-width: $desktop) {
      margin-top: 9px;
      margin-left: -53px;
      border-left: 55px solid transparent;
      border-right: 55px solid transparent;
      border-bottom: 65px solid $primary;
    }
  }

  .tooltip {
    margin-top: 85px;

    @media(min-width: $ipad) {
      margin-top: 126px;
    }

    @media(min-width: $ipad-pro) {
      margin-top: 200px;
    }

    @media(min-width: $desktop) {
      margin-top: 115px;
    }
  }
}


