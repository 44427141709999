header {
  margin: 0 auto !important;
  left: auto !important;
  right: auto !important;

  @media (min-width: $ipad) {
    width: 100% !important;
  }

  @media (min-width: $ipad_pro) {
    width: 100% !important;
  }

  //@media (min-width: $desktop) {
  //  width: 50% !important;
  //}
  //
  //@media (min-width: $desktop_large) {
  //  width: 30% !important;
  //}
}

