.fadeOut{
  opacity:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.fadeIn{
  opacity:1;
  width:100%;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}

.fade-wrapper {
  height: calc(39vh - 76px);

  @media (max-width: $phonesmall) {
    height: calc(65vh - 76px);
  }

  @media (min-width: $ipad) {
    height: calc(55vh - 76px);
  }

  @media (min-width: $ipad_pro) {
    height: calc(44vh - 76px);
  }

  @media (min-width: $desktop) {
    height: calc(40vh - 76px);
  }
}

.fade-wrapper--secondtext {
  padding: 10px;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;


  @media (max-width: $phonesmall) {
    padding-top: 0;
    margin-top: 0px !important;

  }

  @media (min-width: $ipad) {
    margin-top: 40px !important;
  }

  @media (min-width: $ipad_pro) {
    margin-top: 50px !important;
  }

  @media (min-width: $desktop) {
    padding-top: 0 !important;
    margin-top: 20px !important;
  }
}

.MuiFilledInput-root:hover,
.MuiFilledInput-root.Mui-focused {
  background: white !important;
}

.fade-wrapper--login {
  padding: 0 20px;
  background: $light;
  background: rgba(245,245,245,0.9);
  border-radius: 12px;
  padding: 25px 25px 5px 25px;

  form {
    margin: 0 auto;
  }

  .h1--lined--dark.p--small {
    max-width: 100%;
  }

  @media (min-width: $desktop) {
    .image-wrapper.jamie {
      display: none;
    }
  }

  @media (max-width: $desktop) {
    .image-wrapper.jamie {
      max-height: 450px !important;
    }
    .image-wrapper {
      height: 35vh;
    }
  }
  @media (max-width: $ipad) {
    .image-wrapper.jamie {
      max-height: 200px !important;
    }

  }
}
//.frontpage-name--desktop {
//  width: 100% !important;
//
//  @media (max-width: $desktop) {
//    display: none;
//  }
//}

.log-icon-large-text {
  padding: 0 10% 20px 10%;

  @media (min-width: $ipad) {
    padding: 0 10% 20px 10%;
  }

  @media (min-width: $ipad_pro) {
    padding: 0 10% 20px 10%;
  }
}
