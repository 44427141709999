.snackbar {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border: none;

  @media (min-width: 1801px) {
    max-width: 860px;
    background-color: #f8f8f8 !important;
  }

  //@media (min-width: 1801px) {
  //  max-width: 430px;
  //  margin-right: 25px;
  //  margin-bottom: 50px;
  //}

  //@media (max-width: 1800px) {
  //  max-width: 260px;
  //  margin-right: 25px;
  //  margin-bottom: 50px;
  //}
  //
  //@media (max-width: 1510px) {
  //  margin: 0 auto;
  //  max-width: 860px;
  //}

  .MuiSnackbarContent-root {
    background-color: transparent !important;
    border-radius: 0;

    @media (max-width: $desktop) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .MuiButton-containedPrimary {
    border: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media (min-width: 1510px) {
      //max-width: 430px;
      max-width: 100%;
      margin-right: 25px;
      margin-bottom: 50px;
      //border-bottom-left-radius: 12px;
      //border-bottom-right-radius: 12px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }

  @media (min-width: 1280px) {
    width: 100%;
    max-width: 860px;
  }

  @media (min-width: $ipad_pro) {
    width: 100%
  }

  //@media (min-width: $desktop) {
  //  width: 50%
  //}
  //
  //@media (min-width: $desktop_large) {
  //  width: 30%
  //}

  > div {
    width: 100%;

  }

  button span {
    font-weight: normal;
    letter-spacing: 1.5px !important;
  }

  .MuiPaper-elevation6 {
    box-shadow: none;
  }
}

.snackbar--profile {

  .MuiSnackbarContent-message {
    background: white;
    color: black;

    div {
      display: flex;
      flex-direction: row;



      button {
        background: #f8f8f8 !important;
        color: black;
        box-shadow: none;

        .cleaned-link {
          display: flex;
          flex-direction: column;
          /* justify-content: center; */
          align-items: center;
        }
      }
    }
  }
}
